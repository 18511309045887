import { Component, OnInit, Signal } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { User } from './_models/user';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterOutlet, TranslateModule],
})
export class AppComponent implements OnInit {
  isLoggedIn = false;

  currentUser: Signal<User | null>;

  showAdminBoard = false;

  showModeratorBoard = false;

  title = 'AngularFrontend';

  constructor(
    private authService: AuthService,
    private keyCloak: KeycloakService,
    translate: TranslateService,
  ) {
    this.currentUser = this.authService.currentUser;
    // instead of app.module.ts we can also set the default language here
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.isLoggedIn = this.keyCloak.isLoggedIn();
  }

  logout($event: Event): void {
    $event.preventDefault();
    this.keyCloak.logout();
  }
}
